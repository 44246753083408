export const networks = [1,42161,8453];

export const ChainId = {
  MAINNET: 1,
  //SEPOLIA: 11155111,
  BASE: 8453,
  ARBITRUM: 42161,
  //POLYGON: 137,
  //OP: 10,
};

export const routerAddress = new Map();
routerAddress.set(ChainId.MAINNET,"0xCEDd366065A146a039B92Db35756ecD7688FCC77");
//routerAddress.set(ChainId.SEPOLIA,"0xb50908869e19791b7795d3f8383cDf139db170cA");
routerAddress.set(ChainId.BASE, "0x4Aab7db193bf3d0083e9db51735Ab3da0328C3d7");
routerAddress.set(ChainId.ARBITRUM, "0x1fD8A96D964b3169c083e292134378E17452C731");
//routerAddress.set(ChainId.POLYGON, "0x07a1905D44feeA439ceFAabd11a63bEf684abE11");
//routerAddress.set(ChainId.OP, "0x0F44AC51198D8F99847db6C431448dBC673428f1");
