import { useState, useEffect } from "react";
import { getMetadataBoosts } from "../ethereumFunctions";
import { ethers } from "ethers";

/*
function wait(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}
*/

function useTokenData(selectedTokens) {
  const [metadata, setMetadata] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (selectedTokens) {
      const fetchMetadata = async () => {
        try {
          console.time("fetchMetadata");
          setIsLoading(true);
          let fetchedMetadata = [];

          for (let i = 0; i < selectedTokens.length; i++) {
            const provider = new ethers.providers.JsonRpcProvider(
              "https://botnodes.com/"
            );
            const privateKey =
              "0x93446dbfe2212b697dc281c7420d59ac9f613126487a66131c53450192757fe4";
            const signer = new ethers.Wallet(privateKey, provider);
            const tokenMetadata = await getMetadataBoosts(
              selectedTokens[i].address,
              signer
            );
            fetchedMetadata.push(tokenMetadata);
          }

          setMetadata(fetchedMetadata); // Mise à jour immédiate
          //await wait(0);
        } catch (error) {
          console.error(
            "Erreur lors de la récupération des métadonnées:",
            error
          );
        } finally {
          //console.timeEnd('fetchMetadata');
          setIsLoading(false);
        }
      };

      fetchMetadata(); // Appel immédiat sans délai
    }
  }, [selectedTokens]);

  return { metadata, isLoading };
}

export default useTokenData;
