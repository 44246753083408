import React, { useEffect } from "react";
import { useSnackbar } from "notistack";
import { getBalanceAndSymbol, getReserves } from "../ethereumFunctions";
import { removeLiquidity, quoteRemoveLiquidity } from "./LiquidityFunctions";
import {
  RemoveLiquidityField1,
  RemoveLiquidityField2,
} from "../CoinSwapper/CoinField";
import CoinDialog from "../CoinSwapper/CoinDialog";
import WrongNetwork from "../Components/wrongNetwork";
import "../assets/styles.css";
import goldcoin from "../assets/images/goldcoin.png";
import remover from "../assets/images/Remove.png";

function LiquidityRemover(props) {
  const { enqueueSnackbar } = useSnackbar();

  const [dialog1Open, setDialog1Open] = React.useState(false);
  const [dialog2Open, setDialog2Open] = React.useState(false);
  const [wrongNetworkOpen, setwrongNetworkOpen] = React.useState(false);

  const [coin1, setCoin1] = React.useState({
    address: undefined,
    symbol: undefined,
    balance: undefined,
  });
  const [coin2, setCoin2] = React.useState({
    address: undefined,
    symbol: undefined,
    balance: undefined,
  });

  const [reserves, setReserves] = React.useState(["0.0", "0.0"]);
  const [field1Value, setField1Value] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [liquidityTokens, setLiquidityTokens] = React.useState("");
  const [tokensOut, setTokensOut] = React.useState([0, 0, 0]);

  const switchFields = () => {
    setCoin1(coin2);
    setCoin2(coin1);
    setReserves(reserves.reverse());
  };

  const handleChange = {
    field1: (e) => {
      setField1Value(e.target.value);
    },
  };

  const formatBalance = (balance, symbol) => {
    if (balance && symbol)
      return parseFloat(balance).toPrecision(8) + " " + symbol;
    else return "0.0";
  };

  const formatReserve = (reserve, symbol) => {
    if (reserve && symbol) return reserve + " " + symbol;
    else return "0.0";
  };

  const isButtonEnabled = () => {
    const parsedInput = parseFloat(field1Value);
    return (
      coin1.address &&
      coin2.address &&
      parsedInput !== NaN &&
      0 < parsedInput &&
      parsedInput <= liquidityTokens
    );
  };

  const remove = () => {
    console.log("Attempting to remove liquidity...");
    setLoading(true);

    removeLiquidity(
      coin1.address,
      coin2.address,
      field1Value,
      0,
      0,
      props.network.router,
      props.network.account,
      props.network.signer,
      props.network.factory
    )
      .then(() => {
        setLoading(false);
        setField1Value("");
        enqueueSnackbar("Removal Successful", { variant: "success" });
      })
      .catch((e) => {
        setLoading(false);
        enqueueSnackbar("Deployment Failed (" + e.message + ")", {
          variant: "error",
          autoHideDuration: 10000,
        });
      });
  };

  const onToken1Selected = (address) => {
    setDialog1Open(false);

    if (address === coin2.address) {
      switchFields();
    } else if (address) {
      getBalanceAndSymbol(
        props.network.account,
        address,
        props.network.provider,
        props.network.signer,
        props.network.weth.address,
        props.network.coins
      ).then((data) => {
        setCoin1({
          address: address,
          symbol: data.symbol,
          balance: data.balance,
        });
      });
    }
  };

  const onToken2Selected = (address) => {
    setDialog2Open(false);

    if (address === coin1.address) {
      switchFields();
    } else if (address) {
      getBalanceAndSymbol(
        props.network.account,
        address,
        props.network.provider,
        props.network.signer,
        props.network.weth.address,
        props.network.coins
      ).then((data) => {
        setCoin2({
          address: address,
          symbol: data.symbol,
          balance: data.balance,
        });
      });
    }
  };

  useEffect(() => {
    console.log(
      "Trying to get reserves between:\n" + coin1.address + "\n" + coin2.address
    );

    if (coin1.address && coin2.address && props.network.account) {
      getReserves(
        coin1.address,
        coin2.address,
        props.network.factory,
        props.network.signer,
        props.network.account,
        props.network.router
      ).then((data) => {
        setReserves([data[0], data[1]]);
        setLiquidityTokens(data[2]);
      });
    }
  }, [
    coin1.address,
    coin2.address,
    props.network.account,
    props.network.factory,
    props.network.signer,
  ]);

  useEffect(() => {
    if (isButtonEnabled()) {
      console.log("Trying to preview the liquidity removal");
      quoteRemoveLiquidity(
        coin1.address,
        coin2.address,
        field1Value,
        props.network.factory,
        props.network.signer
      ).then((data) => {
        setTokensOut(data);
      });
    }
  }, [
    coin1.address,
    coin2.address,
    field1Value,
    props.network.factory,
    props.network.signer,
  ]);

  useEffect(() => {
    const coinTimeout = setTimeout(() => {
      console.log("Checking balances & Getting reserves...");

      if (coin1.address && coin2.address && props.network.account) {
        getReserves(
          coin1.address,
          coin2.address,
          props.network.factory,
          props.network.signer,
          props.network.account,
          props.network.router
        ).then((data) => {
          setReserves([data[0], data[1]]);
          setLiquidityTokens(data[2]);
        });
      }

      if (coin1.address && props.network.account && !wrongNetworkOpen) {
        getBalanceAndSymbol(
          props.network.account,
          coin1.address,
          props.network.provider,
          props.network.signer,
          props.network.weth.address,
          props.network.coins
        ).then((data) => {
          setCoin1({
            ...coin1,
            balance: data.balance,
          });
        });
      }
      if (coin2.address && props.network.account && !wrongNetworkOpen) {
        getBalanceAndSymbol(
          props.network.account,
          coin2.address,
          props.network.provider,
          props.network.signer,
          props.network.weth.address,
          props.network.coins
        ).then((data) => {
          setCoin2({
            ...coin2,
            balance: data.balance,
          });
        });
      }
    }, 10000);

    return () => clearTimeout(coinTimeout);
  });

  return (
    <div className="window minvw overflowscroll scrollcontainer">
      <CoinDialog
        open={dialog1Open}
        onClose={onToken1Selected}
        coins={props.network.coins}
        signer={props.network.signer}
      />
      <CoinDialog
        open={dialog2Open}
        onClose={onToken2Selected}
        coins={props.network.coins}
        signer={props.network.signer}
      />
      <WrongNetwork open={wrongNetworkOpen} />
      <p className="subtitles correctFont NoMargin NoPadding">
        Liquidity Remover
      </p>

      <div className="coinSelectSection">
        <div className="fullwidth">
          <RemoveLiquidityField1
            activeField={true}
            value={field1Value}
            onClick={() => setDialog1Open(true)}
            onChange={handleChange.field1}
            symbol={coin1.symbol !== undefined ? coin1.symbol : "Select"}
          />
        </div>

        <div item className="fullwidth">
          <RemoveLiquidityField2
            activeField={true}
            onClick={() => setDialog2Open(true)}
            symbol={coin2.symbol !== undefined ? coin2.symbol : "Select"}
          />
        </div>
      </div>

      <div
        container
        direction="row"
        alignItems="center"
        justifyContent="space-around"
        spacing={4}
        className="balance"
      >
        <div
          container
          item
          className="values"
          direction="column"
          alignItems="center"
          spacing={5}
        >
          <p className="subtitles correctFont">Your Balances</p>
          <div>
            <div className="BalanceBoxsm NoMargin NoPadding">
              <p className="BalanceBoxBody">
                {formatBalance(coin1.balance, coin1.symbol)}
              </p>
              <img src={goldcoin} alt="Gold Coin" className="goldCoin"></img>
            </div>
            <div className="BalanceBoxsm">
              <p className="BalanceBoxBody">
                {formatBalance(coin2.balance, coin2.symbol)}
              </p>
              <img src={goldcoin} alt="Gold Coin" className="goldCoin"></img>
            </div>
          </div>

          <p className="subtitles correctFont">Liquidity</p>
          <div>
            <div className="BalanceBoxsm">
              <p className="BalanceBoxBody">
                {formatReserve(reserves[0], coin1.symbol)}
              </p>
              <img src={goldcoin} alt="Gold Coin" className="goldCoin"></img>
            </div>
            <div className="BalanceBoxsm">
              <p className="BalanceBoxBody">
                {formatReserve(reserves[1], coin2.symbol)}
              </p>
              <img src={goldcoin} alt="Gold Coin" className="goldCoin"></img>
            </div>
          </div>
        </div>
        <fieldset className="papercontainer">
          <p className="subtitles correctFont">Tokens Out</p>
          <div className="tokeninrow">
            <p>{formatBalance(tokensOut[1], coin1.symbol)}</p>
            <p>{formatBalance(tokensOut[2], coin2.symbol)}</p>
          </div>
          <hr></hr>
          <p className="subtitles correctFont">Liquidity Pool Tokens in</p>
          <p>{formatBalance(tokensOut[0], "VISTA-LP")}</p>
          <hr></hr>

          <p className="subtitles correctFont">Your Liquidity Pool Tokens</p>
          <div container direction="row" justifyContent="space-between">
            <div className="balanceboxsm">
              <p>{formatReserve(liquidityTokens, "VISTA-LP")}</p>
            </div>
          </div>
        </fieldset>
      </div>
      <p
        style={{
          fontSize: "8px",
          textAlign: "center",
          opacity: 0.75,
          marginTop: 15,
          lineHeight: 1.3,
        }}
      >
        Do not forget to claim rewards before <br />
        adding/removing LP else they will be lost.
      </p>
      <button
        loading={loading}
        valid={isButtonEnabled()}
        success={false}
        fail={false}
        onClick={remove}
        className="deploybutton"
      >
        <h6 className="BalanceBoxBody">Remove</h6>
        <img
          src={remover}
          alt="Gold Coin"
          className="goldCoin NoMargin NoPadding"
        ></img>
      </button>
    </div>
  );
}

export default LiquidityRemover;
