import React from "react";
import vistaStore from "../../assets/images/vistaStore.png";
const VistaStore = () => {
  return (
    <img
      src={vistaStore}
      alt="VistaStore Logo"
      width="60"
      height="60"
      style={{ pointerEvents: "none" }}
    />
  );
};

export default VistaStore;