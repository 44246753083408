import React, { useState, useEffect } from "react";
import "../assets/styles.css";
import goldcoin from "../assets/images/goldcoin.png";
import lightningIcon from "../assets/images/lightning.png";
import { boostIt, checkAvailable } from "../ethereumFunctions"

function StakeBoost(props) {
  const [cost, setCost] = useState("$99");
  const [spotAvailable, setSpotAvailable] = useState("");
  const [pairAddress, setPairAddress] = useState("");

  useEffect(() => {
    const fetchSpotAvailability = async () => {
      try {
        if (props.network.signer) {
          const isAvailable = await checkAvailable(props.network.signer);
          setSpotAvailable(isAvailable ? "Yes" : "No");
        }
      } catch (error) {
        console.error("Error checking spot availability:", error);
        setSpotAvailable("Error");
      }
    };

    fetchSpotAvailability();
  }, [props.network.signer]);

  const handleBoost = async () => {
    await boostIt(pairAddress, props.network.router, props.network.signer)
  };

  return (
    <div className="fitcontent paddingBot">
      <div className="window scrollcontainer lg-padding" role="tabpanel">
        <fieldset>
          <legend>Boost</legend>         
          <p className="subtitles correctFont">
            Is spot available: 
            <span style={{ marginLeft: "10px", fontWeight: "bold" }}>
              {spotAvailable}
            </span>
          </p>
          <p className="subtitles correctFont">
            Cost: 
            <span style={{ marginLeft: "10px", fontWeight: "bold" }}>
              {cost}
            </span>
          </p>
          <p className="subtitles correctFont">Pair Address:</p>
          <div className="BalanceBoxlg pairAddressInput">
            <input
              placeholder="Enter pair address"
              className="BalanceBoxBody"
              value={pairAddress}
              onChange={(e) => setPairAddress(e.target.value)}
            />
          </div>
        </fieldset>
      </div>

      <button onClick={handleBoost} className="swapbutton">
        <h6 className="BalanceBoxBody">Boost</h6>
        <img src={lightningIcon} alt="Lock" className="lockicon" />
      </button>
    </div>
  );
}

export default StakeBoost;