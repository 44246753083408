import React, { useState, useEffect } from "react";
import "../assets/styles.css";
import goldcoin from "../assets/images/goldcoin.png";
import remover from "../assets/images/Remove.png";
import {
  getBalanceAndSymbol,
  getStatsLOCK,
  claimRewardsLOCK,
} from "../ethereumFunctions";
import CoinDialog from "../CoinSwapper/CoinDialog";


function RewardsLocker(props) {
  const [userRewards, setUserRewards] = useState(0);
  const [allTimeRewards, setAllTimeRewards] = useState(0);
  const [totalRewards, setTotalRewards] = useState(0);

  // State for CoinDialog
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedToken, setSelectedToken] = useState(null);

  useEffect(() => {
    if (selectedToken) {
      console.log("selectedToken updated: ", selectedToken);

      const fetchRewards = async () => {
        console.log("Signer:: ", props.network.signer);
        console.log("Account:: ", props.network.account);
        try {
          await fetchR();
        } catch (error) {
          console.error("Error fetching rewards:", error);
        }
      };

      fetchRewards();
      const fetchInterval = setInterval(fetchRewards, 6000);
      return () => clearInterval(fetchInterval);
    }
  }, [selectedToken]);

  async function fetchR() {
    try {
      console.log("selectedtoken is:", selectedToken);
      const stats = await getStatsLOCK(
        selectedToken.address,
        props.network.router,
        props.network.signer
      );
      setUserRewards(stats[0]);
      setAllTimeRewards(stats[1]);
      setTotalRewards(stats[2]);
    } catch (error) {
      console.error("Error fetching rewards:", error);
    }
  }

  async function handleClaim() {
    await claimRewardsLOCK(
      selectedToken.address,
      props.network.signer,
    );
    const stats = await getStatsLOCK(
      selectedToken.address,
      props.network.router,
      props.network.signer
    );
    setUserRewards(stats[0]);
    setAllTimeRewards(stats[1]);
    setTotalRewards(stats[2]);
  }

  const handleOpenDialog = () => {
    setDialogOpen(true);
  };

  const handleCloseDialog = async (address) => {
    setDialogOpen(false);

    if (address && props.network.signer) {
      try {
        console.log("closing dialog with addr", address);
        const data = await getBalanceAndSymbol(
          props.network.account,
          address,
          props.network.provider,
          props.network.signer,
          props.network.weth.address,
          props.network.coins
        );
        if (data != null) {
          console.log("setting selected token");
          setSelectedToken({
            address: address,
            symbol: data.symbol,
            balance: data.balance,
          });
        }
      } catch (err) {
        console.log("handleCLoseERR: ", err);
      }
    }
  };

  return (
    <div className="fitcontent marginBot">
      <CoinDialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        coins={props.network.coins}
        props={props.network.signer}
      />
      <div className="window scrollcontainer lg-padding rewardspage">
        <fieldset>
          <legend>Your rewards (USD)</legend>
          <div className="BalanceBoxsm centered">
            <p className="BalanceBoxBody">{userRewards}</p>
            <img src={goldcoin} alt="Gold Coin" className="goldCoin" />
          </div>
          {selectedToken && (
            <div>
              <p>{selectedToken.symbol}</p>
            </div>
          )}
          <button className="rewardsselect" onClick={handleOpenDialog}>
            <h6 class="unselectable EtherVistaSubTitle">Select Pair</h6>
          </button>

          <button className="swapbutton" onClick={handleClaim}>
            <h6 className="BalanceBoxBody">Claim</h6>
            <img src={remover} alt="swap" className="goldCoin centered" />
          </button>
        </fieldset>
        <fieldset>
          <legend>All time rewards</legend>
          <div className="BalanceBoxsm centered">
            <p className="BalanceBoxBody">{allTimeRewards}</p>
            <img src={goldcoin} alt="Gold Coin" className="goldCoin" />
          </div>
        </fieldset>
      </div>
    </div>
  );
}

export default RewardsLocker;
