import React from "react";
import xpFun from "../../assets/images/xp-fun.png";
const XpFun = () => {
  return (
    <img
      src={xpFun}
      alt="Xp"
      width="60"
      height="60"
      style={{ pointerEvents: "none" }}
    />
  );
};

export default XpFun;