import React, { useState, useEffect } from "react";
import "../assets/styles.css";
import useTokenData from "../hooks/useTokenData";
import notfound from "../assets/images/channels3.png";
import { update, safeLPtransfer, approve } from "../ethereumFunctions"

function Tools(props) { 
    const [tokenaddress, setTokenaddress] = useState("");
    const [amount, setAmount] = useState("");
    const [spender, setSpender] = useState("");
    const [lpaddress, setLpaddress] = useState("");
    const [lpamount, setLpamount] = useState("");
    const [recipient, setRecipient] = useState("");
    const [pairaddress, setPairaddress] = useState("");

    const handleApprove = async () => {
      await approve(tokenaddress, amount, spender, props.network.signer);
    }

    const handleTransfer = async () => {
      await safeLPtransfer(lpaddress, lpamount, recipient, props.network.router);
    }

    const handleUpdate = async () => {
      await update(pairaddress, props.network.router);
    }

  return (
    <div className="window-vista-tools">
      <div className="title-bar">
        <span>TOOLS.exe</span>
      </div>      
      <div className="content">
          <div className="user-info">


            {/* -------------- APPROVE ------------- */}
            <h1 className="tools-titles">APPROVE SPENDER</h1>
            <input 
                className="tools-input-update"
                placeholder="Token address" 
                type="text" 
                value={tokenaddress}
                onChange={(e) => setTokenaddress(e.target.value)}
                />
            <div className="tools-input-align">
                <input 
                placeholder="Amount" 
                type="text" 
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
                />
                <input 
                placeholder="Spender" 
                type="text" 
                value={spender}
                onChange={(e) => setSpender(e.target.value)}
                />
            </div>           
            <button className="tools-buttons" onClick={handleApprove}>Approve</button>


            {/* ------------- SAFE TRANSFERT LP ----------- */}
            <h1 className="tools-titles">SAFE TRANSFER LP</h1>
            <input 
                className="tools-input-update"
                placeholder="LP address" 
                type="text" 
                value={lpaddress}
                onChange={(e) => setLpaddress(e.target.value)}
                />
            <div className="tools-input-align">
                <input 
                placeholder="Amount" 
                type="text" 
                value={lpamount}
                onChange={(e) => setLpamount(e.target.value)}
                />
                <input 
                placeholder="Recipient" 
                type="text" 
                value={recipient}
                onChange={(e) => setRecipient(e.target.value)}
                />
            </div>
            <button className="tools-buttons" onClick={handleTransfer}>Transfer</button>


            {/* ------------- START RECEIVING ------------ */}
            <h1 className="tools-titles">UPDATE EULER LP BALANCE</h1>
            <input 
            className="tools-input-update" 
            placeholder="Pair address" 
            type="text" 
            value={pairaddress}
            onChange={(e) => setPairaddress(e.target.value)}
            />
            <button className="tools-buttons" onClick={handleUpdate}>Update</button>
          </div>
        </div>
    </div>
  );
}

export default Tools;
