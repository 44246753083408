import React from "react";
import folder from "../../assets/images/Folder.webp";
const FolderIcon = () => {
  return (
    <img
      src={folder}
      alt="Folder Logo"
      width="60"
      height="60"
      style={{ pointerEvents: "none" }}
    />
  );
};

export default FolderIcon;