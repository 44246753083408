import DesktopIcon from "./DesktopIcon";
import LimeIcon from "../Components/Icons/LimeIcon";
import PowershellIcon from "../Components/Icons/PowershellIcon";
import ClippyIcon from "../Components/Icons/ClippyIcon";
import NinjaIcon from "../Components/Icons/NinjaIcon";
import MinesweeperIcon from "../Components/Icons/MinesweeperIcon";
import RoverIcon from "../Components/Icons/RoverIcon";
import DegenIcon from "../Components/Icons/DegenIcon";
import FolderIcon from "./Icons/FolderIcon";
import VistaStore from "./Icons/VistaStore";
import XpFun from "./Icons/XpFun";
import { calculateInitialPosition } from "../utils/calculatePosition";

const DesktopIcons = ({ toggleWindow }) => {
  return (
    <>
    {/* <DesktopIcon
        icon={LimeIcon}
        label="Lime"
        initialPosition={calculateInitialPosition(90, 20)}
        onOpen={() => window.open("https://lime.audio/", "_blank")}
      />

      <DesktopIcon
        icon={PowershellIcon}
        label="Powershell"
        initialPosition={calculateInitialPosition(90, 120)}
        onOpen={() => window.open("https://powershelltoken.com/", "_blank")}
      />

      <DesktopIcon
        icon={ClippyIcon}
        label="Clippy"
        initialPosition={calculateInitialPosition(90, 220)}
        onOpen={() => window.open("https://vistaclippy.xyz/", "_blank")}
      />

      <DesktopIcon
        icon={NinjaIcon}
        label="Ninja"
        initialPosition={calculateInitialPosition(90, 320)}
        onOpen={() => window.open("https://www.ninjacat.xyz/", "_blank")}
      />

      <DesktopIcon
        icon={MinesweeperIcon}
        label="Mine Sweeper"
        initialPosition={calculateInitialPosition(90, 420)}
        onOpen={() => window.open("https://www.mine-sweeper.fun/", "_blank")}
      />

      <DesktopIcon
        icon={RoverIcon}
        label="Rover"
        initialPosition={calculateInitialPosition(90, 520)}
        onOpen={() => window.open("https://www.vistarover.xyz/", "_blank")}
      />

      <DesktopIcon
        icon={DegenIcon}
        label="Degen"
        initialPosition={calculateInitialPosition(80, 20)}
        onOpen={() => window.open("https://degenads.com/vista/", "_blank")}
      /> */}

      <DesktopIcon
        icon={FolderIcon}
        label="Vista Apps"
        initialPosition={calculateInitialPosition(90, 20)}
        onOpen={() => toggleWindow("container11")}
      />

      <DesktopIcon
        icon={XpFun}
        label="xpdotfun"
        initialPosition={calculateInitialPosition(90, 150)}
        onOpen={() => window.open("http://xp.fun/", "_blank")}
      />

      <DesktopIcon
        icon={VistaStore}
        label="Vista store"
        initialPosition={calculateInitialPosition(90, 260)}
        onOpen={() => window.open("https://vistastore.fun/", "_blank")}
      />      
    </>
  );
};

export default DesktopIcons;