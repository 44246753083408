import React, { useState, useEffect } from "react";
import "../assets/styles.css";
import notfound from "../assets/images/channels3.png";
import useTokenData from "../hooks/useTokenData";
import { getContributors } from "../ethereumFunctions";
import lightningIcon from "../assets/images/lightning.png";

function Boost() {
  const [visibleUserIndex, setVisibleUserIndex] = useState(0);
  const [addresses, setAddresses] = useState([]);
  const [isLoadingAddresses, setIsLoadingAddresses] = useState(true); // Loading state for addresses

  // Fetch addresses from the async function
  useEffect(() => {
    async function fetchAddresses() {
      try {
        const contributors = await getContributors(); // Assumes getContributors returns [{ address: "0x..." }]
        setAddresses(contributors);
        setIsLoadingAddresses(false); // Set loading to false once addresses are fetched
      } catch (error) {
        console.error("Error fetching contributors: ", error);
        setIsLoadingAddresses(false); // Set loading to false even if there's an error
      }
    }

    fetchAddresses();
  }, []);

  // Handles the interval to change visible user
  useEffect(() => {
    const interval = setInterval(() => {
      setVisibleUserIndex((prevIndex) => (prevIndex + 1) % 10);
    }, 3000);
    return () => clearInterval(interval);
  }, []);

  const { metadata, isLoading: isTokenDataLoading } = useTokenData(addresses);

  return (
    <div className="window-vista">
      <div className="title-bar">
        <span>BOOST.exe</span>
      </div>
      <div className="content">
        {/* Show loading message while addresses are being fetched */}
        {isLoadingAddresses || isTokenDataLoading ? (
          <div style={{ color: "white", textAlign: "center" }}>
            <h5>Welcome to Ethervista</h5>
            <p style={{ margin: '20px' }}>
              <img src={lightningIcon} alt="News" className="newsicon" />What's new ?
            </p>
            <ul style={{ width: '100%' }}>
              <li>You can now lock your LP without losing rewards</li>
              <li>Boosts are now available for only $99</li>
              <li>$VISTA holders can now stake their tokens</li>
            </ul>
          </div>
        ) : (
          metadata.map((data, index) => (
            <div
              key={`${addresses[index]?.address}-${index}`} // Combine address and index to ensure uniqueness
              className={`user ${visibleUserIndex === index ? "visible" : ""}`}
            >
              <img
                src={data?.metadata?.[1] || notfound}
                alt="NO COVER IMAGE FOUND"
                className="metadata-cover-boost"
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = notfound;
                }}
              />
              <div className="user-info">
                <h3 className="user-name">{data.name}</h3>
                <p className="crypto-name">{data.symbol}</p>
                <p className="user-description">{data.metadata[2]}</p>
                {/* Hyperlink only adresse-crypto */}
                <a
                  href={`https://dexscreener.com/ethereum/${addresses[index]?.address}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="adresse-crypto"
                >
                  {data.sellFee}
                </a>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
}

export default Boost;
